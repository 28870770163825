import HeaderAppyShop, {getComponentStaticProps, HeaderProps} from '../../components/header/headerAppyShop'
import FooterAppyShop from '../../components/footer/footerAppyShop'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { config } from '../../config-merchant'
import { useTranslation } from 'next-i18next';
import Meta from '../../components/meta/meta';
import { appyShopConfig } from '../../config';
import styles from '../../styles/client-AGR.custom.module.scss';
import Products from "../../components/catalog/items-list";

export default function Home(props: { headerProps: HeaderProps }) {

    const { i18n } = useTranslation('common');

    return (
        <>
            <Meta
                title={""}
                description={config.shopSlogan[i18n.language]}
                image={config.merchantDomain + config.defaultMetaImage}
                metaRobots={true}
            />
            <HeaderAppyShop activepage={'home'} headerProps={props.headerProps} />
            <div id={"main-bg-top"}><img src="/assets/svg/bg-o.svg" alt="Agroetica"/></div>
            <div id={"main-bg-bottom"}><img src="/assets/svg/bg-o.svg" alt="Agroetica"/></div>
            <main>

                <div className={`${styles['home']} ${styles['custom']}`}>

                    <div className={styles["cover"]}>
                        <img src="/assets/images/AGR/home-cover-5.png" alt="Agroetica"/>
                    </div>

                    <div className={styles["teaser"]}>
                        <div>
                            <h1>La Spiruline « made in France » !</h1>
                            <h2>Un produit de qualité cultivé à Cannes</h2>
                            <p className={styles["text-citation"]}>"Dans notre ferme artisanale, nous cultivons une spiruline de qualité, sous le soleil de Cannes ! En <b>poudre</b>, en <b>paillettes</b> ou en <b>comprimés</b>, cet or vert vous apportera bien-être et vitalité. N’hésitez pas à venir nous rencontrer et bonne visite sur notre site !"</p>
                            <p className={styles["signature"]}>— Elena Blanc Faruffini, <span>Artisan Spirulinier</span></p>
                        </div>
                        <img src="/assets/images/AGR/img-elena.png" alt="Elena Blanc, Artisan Spirulinier"/>
                        <img src="/assets/images/AGR/svg-spiruline-parts.svg" alt="Agroetica spiruline cannoise"/>
                    </div>

                    <img className={styles["img-center"]} src="/assets/images/AGR/img-spiruline-types.png" alt="La spiruline de Cannes"/>

                    <div className={styles["icones-values"]}>
                        <div className={styles["icones-values-h"]}>
                            <img src="/assets/images/AGR/svg-icon-proteines.svg" alt="Protéines"/>
                            <p>Protéines</p>
                            <p>3 fois plus que le boeuf</p>
                        </div>
                        <div className={styles["icones-values-m"]}>
                            <img src="/assets/images/AGR/svg-icon-vitamines.svg" alt="Vitamines"/>
                            <p>Vitamines</p>
                            <p>toutes sauf la vitamine C</p>
                        </div>
                        <div className={styles["icones-values-l"]}>
                            <img src="/assets/images/AGR/svg-icon-antioxydants.svg" alt="Antioxydants"/>
                            <p>Antioxydants</p>
                            <p>phycocyanine bêtacarotène</p>
                        </div>
                        <div className={styles["icones-values-l"]}>
                            <img src="/assets/images/AGR/svg-icon-fer.svg" alt="Fer"/>
                            <p>Fer</p>
                            <p>25 fois plus que les lentilles</p>
                        </div>
                        <div className={styles["icones-values-m"]}>
                            <img src="/assets/images/AGR/svg-icon-mineraux.svg" alt="Minéraux"/>
                            <p>Minéraux</p>
                            <p>calcium, phosphore et magnésium</p>
                        </div>
                        <div className={styles["icones-values-h"]}>
                            <img src="/assets/images/AGR/svg-icon-acides-amines.svg" alt="Acides aminés"/>
                            <p>Acides aminés</p>
                            <p>source d'acide gamma-linoléique</p>
                        </div>
                    </div>

                    <div className={styles["home-imgs"]}>
                        <div>
                            <img src="/assets/images/AGR/img-spiruline-microscope.png" alt="La spiruline au microscope"/>
                            <div>
                                <p>Qui sommes-nous ?</p>
                                <a href={"/notre-ferme"}><div className={styles["button"]}>En savoir plus</div></a>
                            </div>
                        </div>
                        <div>
                            <img src="/assets/images/AGR/img-spiruline-bassin.png" alt="Nos bassin de culture"/>
                            <div>
                                <p>Notre spiruline 100% cannoise !</p>
                                <a href={"/catalog?category=1"}><div className={styles["button"]}>Tout voir</div></a>
                            </div>
                        </div>
                        <div>
                            <img src="/assets/images/AGR/img-spiruline-recolte.png" alt="La récolte"/>
                            <div>
                                <p>Une question ?</p>
                                <a href={"/contact"}><div className={styles["button"]}>Contactez-nous</div></a>
                            </div>
                        </div>
                    </div>

                    <div className={styles["separator"]}></div>

                    <div className={styles["where-to-buy"]}>
                        <div>
                            <h1>Vente sur le marché<br/>Forville à Cannes</h1>
                            <h2>Le samedi</h2>
                            <p>de 08:30 à 12:30</p>
                            <img src="/assets/images/AGR/img-spiruline-pots.png" alt="En paillettes, poudre ou comprimés"/>
                        </div>
                        <div>
                            <h1>Vente directe à la ferme</h1>
                            <h2>Le mardi et jeudi,<br/>toute la journée et<br/>quand le portail est ouvert</h2>
                            <p>de 09:00 à 17:00</p>
                            <img src="/assets/images/AGR/svg-agroetica-map.png" alt="Où sommes nous à Cannes"/>
                        </div>
                    </div>

                    <a href={"/catalog"}><div className={styles["button"]}>Acheter en ligne</div></a>

                    <div className={styles["separator"]}></div>

                    <div className={styles["teaser"]}>
                        <div>
                            <h1>Notre Spiruline est ...</h1>
                            <h2>... locale, artisanale, nutritive et Bio</h2>
                            <p className={styles["text"]}>Nous cultivons, séchons, emballons, et vendons notre spiruline directement dans notre ferme à Cannes dans les Alpes-Maritimes.</p>
                            <p className={styles["text"]}>
                                Notre spiruline grandit grâce à la chaleur du soleil de Côte d’Azur, chaque étape de la production est réalisée à
                                la main et avec des outils modernes afin de garantir le maximum d’hygiène et qualité. Elle est séchée à température basse (42°)
                                et constante pour conserver ses valeurs nutritives.
                            </p>
                        </div>
                        <img src="/assets/images/AGR/img-spiruline-art.png" alt="Elena Blanc, Artisan Spirulinier"/>
                        <img src="/assets/images/AGR/svg-spiruline-parts.svg" alt="Agroetica spiruline cannoise"/>
                    </div>

                    <a href={"/catalog"}><div className={styles["button"]}>Voir tous nos produits</div></a>


                    <div>
                        <h2>Notre sélection</h2>
                        <div className={styles["home-select-products"]}>
                            <Products
                                nbProducts={4}
                                activeCategory={undefined}
                                activeSorting={"RELEVANCE"}
                                researchQuery={""}
                                setNbProducts={() => {/*ignored*/}}
                                activeTags={[29]}
                            />
                        </div>
                    </div>

                    <div className={styles["partners"]}>
                        <h2>Nos partenaires</h2>
                        <a href={"https://www.vegetime.fr/"} target={"_blank"} rel={"noreferrer"}>
                            <img src="/assets/images/AGR/img-partenaire-vegetime.png" alt=" Apprendre à cuisiner végétal !"/>
                        </a>
                    </div>

                </div>

            </main>

            <FooterAppyShop />
        </>

    )
}

export const getStaticProps = async ({ locale }) => {
    if (config.menu.some(item => item.path === 'home') || config.footerLink.some(item => item.url === '/home')) {
        return {
            props: {
                ...await serverSideTranslations(locale, ['common']),
                headerProps: await getComponentStaticProps(locale)
            },
            revalidate: appyShopConfig.revalidateData
        }
    } else {
        return {
            notFound: true
        }
    }
}



